.main-table-wrapper {
    color: black;
    border-radius: 12px;
    overflow: hidden;
    background: white;
    font-family: "Avantt-Regular";
    src: "url('../assets/fonts/Avantt-Regular.ttf')";
  }
  
  .main-table-wrapper .MuiDataGrid-columnHeaderTitle {
    color: white;
    font-weight: 400;
  }
  
  .MuiSwitch-track {
    background-color: gray;
  }
  
  .MuiSvgIcon-root {
    color: goldenrod;
    fill: goldenrod;
  }
  
  .main-table-wrapper .badge {
    background: white;
    display: block;
    width: 100%;
    height: max-content;
    line-height: 1.5;
  }
  
  .main-table-wrapper-footer {
    /* display: block; */
    font-weight: 800;
    align-items: left;
  }
  
  .dashboardCell {
    justify-content: center !important;
  }
  
  .MuiTablePagination-displayedRows {
    margin: 11px 0 1em;
    padding-left: 15px;
    width: 88px;
    height: 28px;
    padding-top: 4px;
    // background: #282828;
    border-radius: 4px;
  }
  
  .MuiTablePagination-displayedRows,
  .css-levciy-MuiTablePagination-displayedRows p {
    color: black;
    font-size: 14px;
    justify-content: center;
    font-family: "Avantt-SemiBold";
  }
  
  .MuiDataGrid-columnHeader {
    background: #0e0e0efc;
  }
  
  .MuiDataGrid-columnHeader {
    border: 1px solid black !important;
  }
  
  
  
  .text-yellow {
    color: #d3a306;
  }
  
  .text-yellow-dark {
    color: #eccf12ba;
  }
  
  .table-toolbar-wrapper .MuiButtonBase-root {
    color: black;
    font-family: "Avantt-SemiBold";
    src: "url('./assets/fonts/Avantt-SemiBold.ttf)";
    font-size: 14px;
    margin: 4px 10px;
    position: relative;
  }
  
  .table-toolbar-wrapper .MuiButtonBase-root:before {
    content: "";
    width: 1px;
    height: 70%;
    background: black;
    position: absolute;
    right: -10px;
  }
  
  .MuiDataGrid-cell {
    border: 1px solid #2e2e2e;
  }
  
  .table-toolbar-wrapper .MuiButtonBase-root:before {
    border-color: #ffffff00;
    background: transparent;
    color: black;
    height: 150px;
  }
  
  .table-toolbar-wrapper:focus {
    display: flex !important;
    justify-content: right !important;
    border-color: #2e2e2e;
    box-shadow: none;
  }
  
  .MuiDataGrid-filterForm svg {
    fill: black;
  }
  
  .MuiDataGrid-filterForm input,
  select {
    color: black;
  }
  
  .MuiNativeSelect-standard {
    height: 28px;
  }
  
  .MuiDataGrid-filterForm label {
    color: black;
    font-size: 12px;
    font-size: 200;
  }
  
  .MuiDataGrid-filterForm option {
    font-size: 12px;
    background: white !important;
  }
  
  .MuiDataGrid-panel input {
    border-color: #ffffff00 !important;
    box-shadow: none !important;
    color: black;
    height: auto;
  }
  
  .MuiDataGrid-panel:focus {
    float: right;
    border-color: #ffffff00 !important;
    box-shadow: none !important;
  }
  
  .requestId-cell {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    justify-content: center !important;
    text-decoration-thickness: 1px;
  }
  
  .MuiDataGrid-panelContent {
    height: 150px;
    max-height: 150px;
  }