.materialReactTableWrapper {
    th.MuiTableCell-root.MuiTableCell-head {
      background: #0e0e0efc;
      color: #fff;
      position: sticky;
      top: 0;
      justify-content: center;
      font-weight: 400;
      padding: 15px;
      z-index: 1;
    }
    
    td.MuiTableCell-root {
      border-right: 1px solid rgb(65, 65, 65) !important;
      border-bottom: 1px solid rgb(65, 65, 65) !important;
      text-align: center;
      justify-content: center;
    }
    
    .MuiTablePagination-displayedRows {
      color: #000; /* Change the pagination text color to black */
    }
    .MuiTablePagination-input {
      .MuiSelect-select {
        color: #000; /* Change the rows per page dropdown text color to black */
      }
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      color: #fff; /* Change the filter input text color here */
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd{
      color: #000;
    }
    .MuiChip-label.MuiChip-labelMedium {
      color: #fff; /* Change the text color of the selected MUI Chip */
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #fff; /* Change the filter input border color here */
    }
    
    .Mui-TableHeadCell-content {
      justify-content: center;
    }
    
    .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
      // position: absolute;
      // bottom: 12px;
      // width: calc(100% - 35px);
    }
    /* Add the following style to change the multi-select value text color to white */
    .MuiSelect-select.MuiSelect-select.MuiSelect-select {
      color: #fff;
    }
    .MuiSelect-select.MuiSelect-select.MuiSelect-select.MuiTablePagination-select {
      color: #000;
    }
  
  }
  