div[role="combobox"]{
    box-shadow: none !important;
    /* width: 2px; */
}
#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(1) > div > input{
    box-shadow: none !important;
    padding: 0px;
    /* margin-left:-5.2px; */
    
}

/* .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-top: 8px;
    padding: 0;
} */

#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(1) > div > svg{
    padding: 0;
    margin-top: 8px;

}
.rs-picker-toggle-placeholder{
    color:transparent;
}
.rmsc .dropdown-container:focus-within{
    box-shadow: none;
    border: none;
    /* border-bottom: 1px solid black; */
}
.rmsc .dropdown-container{
    border: none
}
.rs-picker-toggle .rs-picker-toggle-active .rs-btn .rs-btn-default{
    box-shadow: none !important;
}
.rs-picker-default .rs-picker-toggle{
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 0;
    /* max-height: 1px; */
}
.rmsc .dropdown-container{
    max-height: 28px;
    max-width: 160px;
    border-radius: 0;
    /* border-bottom: 1px solid black; */
    
}

.rmsc{
    --rmsc-main: none;
    --rmsc-h: 32px;
}

.rmsc .dropdown-content > *{
   font-size: 12px;
   height: 240px;
   /* overflow: scroll; */
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
    border-color: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
    background-color: transparent;
    border-color: #181817;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
background-color: transparent;
}
.rs-calendar-table-cell-in-range:before{
    background-color: transparent !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content{
    /* background-color: transparent !important; */
    background-color:rgba(220, 205, 121, 0.5);
}
.rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content :hover{
    color:#181817
}

.rs-picker{
    max-width: 100px;
    max-height: 50px;
}

.rs-picker-toolbar-ranges .rs-btn{
    color:black
}

.rs-picker-toolbar-right .rs-btn{
    color:black;
    background-color: #ffe552;
} 

.rs-calendar-table-cell-day{
    color:#181817;
}

.rs-picker-toggle-read-only{
    opacity: 15;
    
}



/* .rmsc .dropdown-heading .dropdown-heading-value{
    flex: 1;
}
#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(7) > div > div > div > div,
#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(5) > div > div > div > div{
    flex: 0.6;
} */
.dropdown-content > *{
    max-height:440px;
    max-width: 400px;
}

#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(3) > div > div > div > svg > path,
#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(7) > div > div > div > svg > path,
#root > div > div.App-header > div > div > div.my-3.my-md-5 > div > div > div > div > div.table-responsive > table > thead > tr > th:nth-child(4) > div > div > div > svg > path{
    d: path("M 12 3 c 2.755 0 5.455 0.232 8.083 0.678 c 0.533 0.09 0.917 0.556 0.917 1.096 v 1.044 a 2.25 2.25 0 0 1 -0.659 1.591 l -5.432 5.432 a 2.25 2.25 0 0 0 -0.659 1.591 v 2.927 a 2.25 2.25 0 0 1 -1.244 2.013 L 9.75 21 v -6.568 a 2.25 2.25 0 0 0 -0.659 -1.591 L 3.659 7.409 A 2.25 2.25 0 0 1 3 5.818 V 4.774 c 0 -0.54 0.384 -1.006 0.917 -1.096 A 48.32 48.32 0 0 1 12 3 Z");
    cursor: pointer;
}



.rs-btn-close .rs-icon{
    margin-top: 0.8em;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
    top:14px;
    width: 1.2em;
    height: 1.2em;
}
.rs-picker-toggle-textbox{
    top: 6.2px;
}
.rs-calendar-table-cell-content>* :hover{
    /* background-color:rgb(255, 229, 82, 0.5);
     */
     background-color: #ffe552;
    color:black,
}
.rs-calendar-table-cell:hover{
    /* background-color: rgb(255, 229, 82, 0.5); */
    background-color: #ffe552;
    color:black,
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
    background-color: #ffe552;
    color: black;
    border-color: black;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    /* background-color: rgb(255, 229, 82, 0.5); */
    background-color: #ffe552;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
    -webkit-box-shadow: inset 0 0 0 1px #181817;
}
.rs-calendar-table-cell-day{
    color:black
}



.rs-calendar-table-cell-content-selected:hover {
    /* background-color:rgb(255, 229, 82, 0.5); */
    background-color: #ffe552;
    
     /* background-color: red; */
}
.rs-calendar-table-cell:hover{
    background-color: #ffe552;
}

/* div[role="gridcell"] > * :hover{
    background-color: #ffe552;
} */

.rs-calendar-table-cell .rs-calendar-table-cell-selected .rs-calendar-table-cell-selected-start:hover{
    background-color: #ffe552;
}
.rs-calendar-table-cell-content:hover{
    background-color: #ffe552;
}
.rs-calendar-table-cell-day-selected :hover{
    background-color: #ffe552;
}
/* .rs-calendar-table-cell-content */

.rs-calendar-table-cell-in-range, .rs-calendar-table-cell-selected{
    background-color: rgb(255, 229, 82, 0.5);
    /* background-color: #ffe552; */
}

 .rs-calendar-table-cell-selected-start{
    background-color: #ffe552;
}

.rs-calendar-table-cell:hover{
    background-color: #ffe552;
}

